import { loadActiveEvent } from '~/api/loadEvents'

export function useIsConferenceMode() {
  const isConferenceMode = ref(false)

  const client = useTypedSupabaseClient()
  useAsyncData(async () => await loadActiveEvent(client)).then((activeEvent) => {
    isConferenceMode.value = activeEvent.data.value?.public_sign_up ?? false
  })

  return isConferenceMode
}
